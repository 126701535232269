import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Shaun | I Am Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'I Am Engineer', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Shaun',
  subtitle: 'I Am Engineer',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: 'Shaun has spent the past 2 decades building out large-scale, secure infrastructure in all corners of the world. Having dabbled in everything from his humble beginnings as a Linux Systems Administrator, to building out state-of-the-art data centres, high-density WiFi installations, security solutions for large government contracts and globally distributed organisations, and designing cross-border solutions for numerous large businesses entering the Chinese market - he has seen it all, and done it all.',
  paragraphTwo: 'Today he enjoys solving complex technical challenges with a focus on simple, elegant and secure solutions.',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project_trustedserver.png',
    title: 'TrustedServer',
    info: 'Designed and developed an immutable, scalable, rolling-release Linux Operating System with security at its core.',
    info2: 'In-memory ephermeral booting, reproducible builds to protect against build pipeline attacks, cryptographically signed and verified to defend against a variety of on-server attack vectors, A-B auto-rollback booting.',
    info3: 'Reviewed by security experts worldwide, audited by PwC, and a high-value bug bounty program all ensure industry-defining quality.',
    url: 'https://www.expressvpn.com/features/trustedserver',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project_esp32.png',
    title: 'Integrated Air Quality Monitor development',
    info: 'Developed an integrated air quality monitor using ESP32 hardware and MicroPython, interfacing with multiple backends, including InfluxDB, measuring PM1, PM2.5, CO2 concentration, Temperature, Humidity, Air Pressure',
    info2: 'Open sourcing of specific modules for interfacing with sensors such as the PMS7003 and CO2 sensors.',
    url: '',
    repo: 'https://github.com/shaunrs/micropython-pms7003', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: '',
  //   title: 'aptsign - Secure package delivery pipeline for Debian',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: '', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  link: 'https://linkedin.com/in/shaunrs',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/shaunrs',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/shaunrs',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
